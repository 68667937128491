<template>
  <div class="card text-center my-0 mx-auto" style="width: 320px">
    <div class="card-header">
      <h5 class="mb-1">Forgot password</h5>
    </div>
    <form v-on:submit.prevent="reset" class="card-body">
      <fieldset>
        <div class="mb-2">
          <input id="email" type="email" class="form-control" v-model="form.email" placeholder="Email" required autofocus />
        </div>
        <button type="submit" class="btn btn-primary btn-block mb-2">Send me a link</button>
      </fieldset>
        <div class="alert alert-success" v-if="feedback">{{ feedback }}</div>
      <div v-if="errors.email" class="alert alert-danger mb-2">
        <div v-for="error in errors.email" :key="error">{{ error }}</div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
      },
      feedback: "",
      errors: {},
    };
  },
  methods: {
    async reset() {
      try {
        this.errors = {};
        const { data } = await this.$http.post("/forgot-password", this.form);
        this.feedback = data.message;
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
  },
};
</script>